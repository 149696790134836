import {createAction, props} from '@ngrx/store';
import {TimelineAsset, TimeLineContentResponse, TimelineParam, UpdatedTimeline} from './interfaces/timeline.modal';


export const buildFullTimelineData = createAction(
  '[Timeline module] build full timeline data ',
  props<{ timelineAsset: TimelineAsset, trackingEnabled: boolean, timelineParam: TimelineParam }>()
);

export const getTimelineContentData = createAction(
  '[Timeline module] get timeline event data ',
  props<{timelineParam: TimelineParam, timelineAsset: TimelineAsset }>()
);

export const getTimelineContentDataSuccess = createAction(
  '[Timeline module] get timeline event data type success ',
  props<{ response: TimeLineContentResponse[] }>()
);

export const getTimelineContentDataFailure = createAction(
  '[Timeline module] get timeline event data type failure ',
  props<{ error: Error }>()
);

export const checkTimelineTrackingEnabled = createAction(
  '[Timeline module] get timeline data traking status',
  props<{ timelineParam: TimelineParam, timelineAsset: TimelineAsset }>()
);

export const checkTimelineTrackingEnabledSuccess = createAction(
  '[Timeline module] get timeline data traking status response',
  props<{ timelineAsset: TimelineAsset, trackingEnabled: boolean }>()
);

export const checkTimelineTrackingFailure = createAction(
  '[Timeline module] get timeline data traking status failure',
  props<{ timelineAsset: TimelineAsset, error: Error }>()
);

export const updateTrackableLpOrder = createAction(
  '[Timeline module] updateTrackableLpOrder',
  props<{ lpType: string, lpId: string, orderId: string, timelineAsset: TimelineAsset, timelineParam: TimelineParam }>()
);

export const updateTrackableLpOrderSuccess = createAction(
  '[Timeline module] updateTrackableLpOrder success',
  props<{ updateTimeline: UpdatedTimeline }>()
);

export const updateTrackableLpOrderFailure = createAction(
  '[Timeline module] updateTrackableLpOrder failure',
  props<{ error: Error }>()
);

export const toggleTimelineSelection = createAction(
  '[Timeline module] selection timeline',
  props<{ selected: boolean,selectedIndex:number}>()
);

export const getAssignedCarrier = createAction(
  '[Timeline module] get assigned carrier',
  props<{ param: any }>()
);

export const getAssignedCarrierSuccess = createAction(
  '[Timeline module] get assigned carrier success',
  props<{ data: any }>()
);
export const stopAssignedCarrierPolling = createAction(
  '[Timeline module] stop assigned carrier interval',
);