import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, of} from 'rxjs';
import {catchError, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {DataService} from './data.service';
import {
  addGeofence,
  addGeofenceSuccess,
  deleteGeofence,
  deleteGeofenceSuccess,
  failure,
  getActualPathsForCarrier,
  getActualPathsForCarrierSuccess,
  getCarGpsHistoricalData,
  getCarGpsHistoricalDataSuccess,
  getExpectedPathsForCarrier,
  getExpectedPathsForCarrierSuccess,
  getGeoFenceById,
  getLinkedLocations,
  getLinkedLocationsSuccess,
  getMapIconSettingsByModule,
  getMapIconsSettingsSuccess,
  loadAssetsLinkedData,
  loadAssetsLinkedDataSuccess,
  loadAssignedOrdersGroupData,
  loadAssignedOrdersGroupDataSuccess,
  loadDistanceMoved,
  loadDistanceMovedSuccess,
  loadEventsData,
  loadEventsDataCount,
  loadEventsDataCountSuccess,
  loadEventsDataSuccess,
  loadEventStatusData,
  loadEventStatusDataSuccess,
  loadFuelData,
  loadFuelDataSuccess,
  loadGeoAssetsById,
  loadGeoAssetsByIdSuccess,
  loadGeofences,
  loadGeofencesSuccess,
  loadMarkerIcons,
  loadMarkerIconsSuccess,
  loadSelectedTrackableVehicles,
  loadSelectedTrackableVehiclesSuccess,
  loadSensorData,
  loadSensorDataSuccess,
  loadSpeedData,
  loadSpeedDataSuccess,
  loadVehicles,
  loadVehiclesSuccess,
  updateGeoFenceById,
  updateGeofences,
  updateGeofencesSuccess,
  updateMapIconsByModuleType,
  updateMapIconSettingsSuccess,
  updateVehicleInfo,
  updateVehicleInfoSuccess
} from './fleet.action';


import {TrackerMapIconsSettings} from '../../app/settings/settings-models';
import {PathService} from '../../app/new-fleet/services/path.service';
import {MessageService} from 'primeng/api';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { selectGeofences } from './fleet.selector';
import { AssetService as MainAssetsService} from '../../services/asset.service';
import { TrackingService } from '../../services/tracking.service';

@Injectable()
export class FleetEffects {
  loadVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadVehicles),
      switchMap((action) =>
        from(this.dataService.getAllTrakableVehicles(action.moduleType, action.isCount).pipe(
          switchMap((value) => [
            loadVehiclesSuccess({all_vehicles: value, module: action.moduleType}),
            loadAssignedOrdersGroupData({module: action.moduleType}),]),
          catchError((error) => of(failure({error})))
        ))
      )
    )
  );
  loadSelectedTrackableVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSelectedTrackableVehicles),
      switchMap((action) =>
        from(this.dataService.getAllTrakableVehicles(action.moduleType, action.isCount, action.fI, action.fE, action.assetIds).pipe(
          switchMap((value) => [
            loadSelectedTrackableVehiclesSuccess({ selected_vehicles: value, module: action.moduleType })]),
          catchError((error) => of(failure({ error })))
        ))
      )
    )
  );
  updateVehicleInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateVehicleInfo),
      switchMap((action) =>
        from(this.dataService.getAssetDetails(action.assetId).pipe(
          map((value) => updateVehicleInfoSuccess({selectedVehicle: value, module: action.module})),
          catchError((error) => of(failure({error})))
        ))
      )
    )
  );
  loadDistanceMoved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDistanceMoved),
      switchMap((action) =>
        from(this.dataService.getCarDistanceMovedData(action.startDate, action.endDate, action.assetId).pipe(
          map((value) => loadDistanceMovedSuccess({distanceMovedData: value})),
          catchError((error) => of(failure({error})))
        ))
      )
    )
  );
  loadSpeedData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSpeedData),
      switchMap((action) =>
        from(this.dataService.getCarAnalyticssHistoricDataById(action.startDate, action.endDate, action.assetId).pipe(
          map((value) => loadSpeedDataSuccess({speedData: value})),
          catchError((error) => of(failure({error})))
        ))
      )
    )
  );
  loadFuelData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFuelData),
      switchMap((action) =>
        from(this.dataService.getCarAnalyticssHistoricDataById(action.startDate, action.endDate, action.assetId).pipe(
          map(() => loadFuelDataSuccess({fuelData: this.dataService.buildFuelDataForLastTwoHours(), assetId: action.assetId, moduleName: action.moduleName})),
          catchError((error) => of(failure({error})))
        ))
      )
    )
  );
  loadSensorData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSensorData),
      switchMap((action) =>
        from(this.dataService.getCarSensorData(action.startDate, action.endDate, action.assetId, action.series).pipe(
          map((value) => loadSensorDataSuccess({sensorData: value})),
          catchError((error) => of(failure({error})))
        ))
      )
    )
  );
  loadEventsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEventsData),
      switchMap((action) =>
        from(this.dataService.getCarEventAndStatusData(action.startDate, action.endDate, action.assetId, action.skip, action.limit, action.types, action.status).pipe(
          map((value) => loadEventsDataSuccess({eventsData: value})),
          catchError((error) => of(failure({error})))
        ))
      ),
    )
  );
  loadEventsDataCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEventsDataCount),
      switchMap((action) =>
        from(this.dataService.getCarEventAndStatusCount(action.startDate, action.endDate, action.assetId, action.types, action.status).pipe(
          map((value) => loadEventsDataCountSuccess({eventsDataCount: value})),
          catchError((error) => of(failure({error})))
        ))
      )
    )
  );
  loadEventsStatusData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEventStatusData),
      switchMap((action) =>
        from(this.dataService.getAllCarEventsAndStatus(action.typeString).pipe(
          map((value) => loadEventStatusDataSuccess({eventsStatusData: value})),
          catchError((error) => of(failure({error})))
        ))
      )
    )
  );
  loadAssetsLinkedData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAssetsLinkedData),
      switchMap((action) =>
        from(this.dataService.getLinkedAssets(action.assetId).pipe(
          map((value) => loadAssetsLinkedDataSuccess({assetsLinked: value})),
          catchError((error) => of(failure({error})))
        ))
      )
    )
  );
  /*  loadAssignedOrdersData$ = createEffect(() =>
      this.actions$.pipe(
        ofType(loadAssignedOrdersData),
        mergeMap((action) =>
          from(this.dataService.getAllActiveOrdersForAgent(action.agentId, action.isMinifiedData).pipe(
            map((value) => loadAssignedOrdersDataSuccess({assignedOrders: value, agentId: action.agentId})),
            catchError((error) => of(failure({error})))
          ))
        )
      )
    );*/
  loadAssignedOrdersGroupData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAssignedOrdersGroupData),
      mergeMap((action) =>
        from(this.dataService.getActiveOrdersGroup(action.module, action.carrierId).pipe(
          map((value) => loadAssignedOrdersGroupDataSuccess({
            data: value,
            agentId: action.carrierId,
            module: action.module
          })),
          catchError((error) => of(failure({error})))
        ))
      )
    )
  );
  loadGeofences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGeofences),
      withLatestFrom(this.store.select(selectGeofences)),
      switchMap(([action,stateData]) => {
        if(stateData && !action.isLoadGeofence){
          return of(loadGeofencesSuccess({geofences: stateData}))
        }else {
          return from(this.dataService.getAll().pipe(
            switchMap((value) => [loadGeofencesSuccess({geofences: value}), updateGeofences()]),
            catchError((error) => of(failure({error})))
          ))
        }
        }
      )
    )
  );
  loadMarkerIcons = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMarkerIcons),
      mergeMap((action) =>
        from(this.dataService.getTrackIconsByModuleType(action.moduleType, action.assetType).pipe(
          map((data) => loadMarkerIconsSuccess({data, module: action.moduleType})),
          catchError((error) => of(failure({error})))
        ))
      )
    )
  );
  addGeofences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addGeofence),
      switchMap((action) =>
        from(this.dataService.addGeofence(action.newGeofence).pipe(
          tap(d => d['success'] ? this.message.add({severity:'success', summary:'Geofence added Successfully'}) : null),
          switchMap(() => [loadGeofences({isLoadGeofence : false}), addGeofenceSuccess()]),
          catchError((error) => of(failure({error})))
        ))
      )
    )
  );
  deleteGeofence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteGeofence),
      switchMap((action) => {
       return from(this.dataService.deleteById(action.geofenceId).pipe(
        tap(d => d['success'] ? this.message.add({severity:'success', summary:'Geofence deleted Successfully'}) : null),
          switchMap((value) =>  {
         if(value){
           return [loadGeofences({isLoadGeofence:false}), deleteGeofenceSuccess()]
         } else {
          return []
        }
          }
           ),
          catchError((error) => of(failure({error})))
        ))
      }
        
      )
    )
  );
  loadGeofenceCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateGeofences),
      switchMap(() =>
        from(this.dataService.getAllGeoFenceAssetCount().pipe(
          map((value) => updateGeofencesSuccess({geofencesCount: value})),
          catchError((error) => of(failure({error})))
        ))
      )
    )
  );
  getMapIconSettingsByModule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMapIconSettingsByModule),
      switchMap((action) =>
        from(this.dataService.getMapIconConfigurationSettingsByModuleType(action.moduleType).pipe(
          map((value: TrackerMapIconsSettings) => getMapIconsSettingsSuccess({data: value})),
          catchError((error) => of(failure({error})))
        ))
      )
    )
  );


  loadGeoAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGeoAssetsById),
      switchMap((action) =>
        from(this.dataService.getGeoAssetsById(action.geofenceId).pipe(
          map((value) => loadGeoAssetsByIdSuccess({geoAssets: value, module: action.module})),
          catchError((error) => of(failure({error})))
        ))
      )
    )
  );
// update map icon settings by moduleType
  updateMapIconsByModuleType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMapIconsByModuleType),
      switchMap((action) =>
        from(this.dataService.updateMapIconConfigurationSettingsByModuleType(action.moduleType, action.payload).pipe(
          map((value: TrackerMapIconsSettings) => updateMapIconSettingsSuccess({data: value, moduleType: action.moduleType})),
          catchError((error) => of(failure({error})))
        ))
      )
    )
  );

  getExpectedPathForCarrier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getExpectedPathsForCarrier),
      switchMap((action) =>
        from(this.pathService.getPathData(action.carrierId, action.moduleType, 'expected')
          .pipe(
            tap(d => !d['success'] ? this.message.add({severity: 'error', data: d['msg']}) : null),
            map((data) => getExpectedPathsForCarrierSuccess({data})),
            catchError((error) => of(failure({error})))
          )
        )
      )
    )
  );
  getActualPathForCarrier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getActualPathsForCarrier),
      switchMap((action) =>
        from(this.pathService.getPathData(action.carrierId, action.moduleType, 'actual')
          .pipe(
            tap(d => !d['success'] ? this.message.add({severity: 'error', data: d['msg']}) : null),
            map((data) => getActualPathsForCarrierSuccess({data})),
            catchError((error) => of(failure({error})))
          )
        )
      )
    )
  );

  getLinkedLocationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLinkedLocations),
      switchMap((action)=>
        from(this.dataService.getLinkedLocations(action.cityId)
        .pipe(
          map((data) => getLinkedLocationsSuccess({data, cityId: action.cityId})),
          catchError((e) => of(failure({error:e})))
        )
        )
      )
    )
  )
  getGeoFenceByAssetId$ = createEffect(() =>
  this.actions$.pipe(
    ofType(getGeoFenceById),
    switchMap((action) => 
      from(
        this.mainAssetsService.geoFenceByAssetId(action.assetId)
      ).pipe(
        map((res)=> updateGeoFenceById({geofence: res, geofenceId: action.assetId,consignmentId:action.consignmentId}))
      )
    )
  )
)
  getCarGpsHistoricalData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCarGpsHistoricalData),
      switchMap((action) =>
        from(
          this.trackingService.getCarGpsHistoricalData(action?.startDate, action?.endDate, action?.id)
        ).pipe(
          map((data: any) => {
            return getCarGpsHistoricalDataSuccess({ data })
          })
        )
      )
    ))

  constructor(
    private actions$: Actions,
    private dataService: DataService,
    private pathService: PathService,
    private message: MessageService,
    private store: Store<AppState>,
    private mainAssetsService: MainAssetsService,
    private trackingService: TrackingService,
    
  ) {
  }

}
