<!-- <div class="searchBoxGrid">
<div class="md-form">
  <span (click)="clearSearchedText()" *ngIf="searchText" class="cursorPointer">
    <i class="pi pi-times clear"></i>
  </span>
  <span>
    <i class="pi pi-search"></i>
  </span>
  <input (ngModelChange)="searchForSelectedText($event)" [(ngModel)]="searchText" class="form-control searckBox" id="searchBox"
    name="searchForAsset" placeholder={{getLangLabelForSearch(placeholderValue)}}
    type="text">
</div>
</div> -->
<div class="searchBoxGrid">
  <span class="p-input-icon-left p-input-icon-right full-width">
    <i class="pi pi-search icon-hard"></i>
    <input [(ngModel)]="searchText" class="form-control searckBox" id="searchBox" (input)="handleInputChange($event)"
           name="searchForAsset" placeholder={{getLangLabelForSearch(placeholderValue)}}
           type="text">
    @if (searchText) {
      <i (click)="clearSearchedText()" class="pi pi-times clear icon-hard" style="cursor: pointer;"></i>
    }
  </span>
</div>
