import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { from, interval, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, startWith, switchMap, takeUntil } from 'rxjs/operators';
import {TimelineService} from './timeline.service';
import {TimelineActions} from '.';
import { getAssignedCarrierSuccess } from './timeline.action';
import { DmsAssignmentService } from '../../app/delivery-managenment-system/services/assignment.service';


@Injectable()
export class TimelineEffects {
  private stopPolling$ = new Subject<void>();
  getTimelineEventData$ = createEffect(() =>

    this.actions$.pipe(
      ofType(TimelineActions.getTimelineContentData),
      switchMap((action) =>
        this.timelineService.getTimeLineContent(action.timelineParam).pipe(
          mergeMap((value) => {
            if (value) {
              const asset = {...action.timelineAsset};
              asset['timeLine'] = value;

              if (asset?.moduleType === 'o2d') {
                const timelineSuccess = TimelineActions.getTimelineContentDataSuccess({ response: value });
                const checkTrackingStatus = TimelineActions.checkTimelineTrackingEnabled({timelineParam: action.timelineParam, timelineAsset: asset});
                const buildTimeLine = TimelineActions.buildFullTimelineData({
                  timelineAsset: asset,
                  trackingEnabled: false,
                  timelineParam: action.timelineParam
                });
                if(action.timelineParam.deliveryId){
                return [checkTrackingStatus,timelineSuccess]
                }
                else{
                  return [timelineSuccess, buildTimeLine]
                }

              } else {

                const buildTimeLine = TimelineActions.buildFullTimelineData({
                  timelineAsset: asset,
                  trackingEnabled: false,
                  timelineParam: action.timelineParam
                });
                const timelineSuccess = TimelineActions.getTimelineContentDataSuccess({ response: value });
                return [buildTimeLine,timelineSuccess]
                
              }

            }
          }),
          catchError((error) => of(TimelineActions.getTimelineContentDataFailure({error: error})))
        )
      )
    )
  );
  checkTimelineTrackingEnabled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimelineActions.checkTimelineTrackingEnabled),
      switchMap((action) =>
        from(this.timelineService.getTimeLineTrackingStatus(action.timelineParam.deliveryId).pipe(
          map((value) => {
            return TimelineActions.buildFullTimelineData({
              timelineAsset: action.timelineAsset,
              trackingEnabled: value.status,
              timelineParam: action.timelineParam
            });
          }),
          catchError(() => of(TimelineActions.buildFullTimelineData({
            timelineAsset: action.timelineAsset,
            trackingEnabled: false,
            timelineParam: action.timelineParam
          })))
        ))
      )
    )
  );
  updateTrackableLpOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimelineActions.updateTrackableLpOrder),
      mergeMap((action) =>
        from(this.timelineService.getUpdatedTimeline(action.lpId, action.lpType, action.orderId).pipe(
          map((value) => {
            const asset = {...action.timelineAsset};
            asset['timeLine'] = value?.timeLine;
            return TimelineActions.buildFullTimelineData({
              timelineAsset: asset,
              trackingEnabled: true,
              timelineParam: action.timelineParam
            });
          }),
          catchError((error) => of(TimelineActions.updateTrackableLpOrderFailure({error: error})))
        ))
      )
    )
  );
  getAssignedCarrier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimelineActions.getAssignedCarrier),
      switchMap((action) =>
        interval(30000).pipe(
          startWith(0),
          switchMap(() =>
            from(this.assignmentService.getAllAssignedCarriers(action?.param)).pipe(
              map((value) => getAssignedCarrierSuccess({ data: value })),
              catchError(() => of(getAssignedCarrierSuccess({ data: null })))
            )
          ), takeUntil(this.stopPolling$)
        )
      )
    )
  );
  stopAssignedCarrierPolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimelineActions.stopAssignedCarrierPolling),
      switchMap(() => {
        this.stopPolling$.next();
        return [];
      })
    )
  );


  constructor(private actions$: Actions, private timelineService: TimelineService, private assignmentService: DmsAssignmentService) {
  }

}
