import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../../services/config.service';

import moment from 'moment-timezone';
import * as _ from 'lodash';
import {forEach, merge, orderBy} from 'lodash';
import {CONSTANT} from '../../../config/constant';
import {NamingConventionFilterPipe} from '../../../pipes/namingConvention.pipe';
import { of } from 'rxjs';
import { IAssignmentWindowForO2DWithWindow } from '../../../state/Settings/interfaces/orderSettings.interface';
import { LangUtilService } from '../../../app/util/lang-util.service';

const MMD_MODULE_NAME = CONSTANT.DMS_MODULES.MMD.MODULE;
 @Injectable({
  providedIn: 'root'
 })
 export class DmsAssignmentService {

   constructor(private http: HttpClient, public configService: ConfigService,public langUtilService:LangUtilService) { }

   getAllAssigmentCounts(arrayFilters, startDate?, endDate?) {
     let url = this.configService.appConfig.appBaseUrl + 'deliveryManagement/management/count';
     let queryUrl = '';
     forEach(arrayFilters, (filter) => {
       const value = filter.val;
       const key = filter.key;
       if ((value === false || !(value === null || value === undefined || value === '')) && key ) {
         if (queryUrl.length === 0) {
           queryUrl = `?${key}=${value}`;
         } else {
           queryUrl = `${queryUrl}&${key}=${value}`;
         }
       }
     });
     url = url + queryUrl;
     if (startDate) {
       url = url + '&startDate=' + startDate;
     }
     if (endDate) {
       url = url + '&endDate=' + endDate;
     }
     return this.http.get(url);
   }

   getAllAssignments(moduleName, skip, limit, startDate?, endDate?, deliveryStatus?, searchedText?, lpName?, orderPickUpCityId?, orderPickUpStoreId?) {
     let queryUrl = this.configService.appConfig.appBaseUrl + 'deliveryManagement/management?module=' + moduleName + '&skip=' + skip + '&limit=' + limit;
     if (startDate) {
       queryUrl = queryUrl + '&startDate=' + startDate;
     }
     if (endDate) {
       queryUrl = queryUrl + '&endDate=' + endDate;
     }
     if (deliveryStatus) {
       queryUrl = queryUrl + '&deliveryStatus=' + deliveryStatus;
     }
     if (searchedText) {
       queryUrl = queryUrl + '&search=' + searchedText;
     }
     if (lpName) {
       queryUrl = queryUrl + '&lpName=' + lpName;
     }
     if (orderPickUpCityId) {
       queryUrl = queryUrl + '&orderPickUpCityId=' + orderPickUpCityId;
     }
     if (orderPickUpStoreId) {
       queryUrl = queryUrl + '&orderPickUpStoreId=' + orderPickUpStoreId;
     }
     return this.http.get(queryUrl);
   }

   getAssignmentById(deliveryId, fInclude?) {
     let queryUrl = this.configService.appConfig.appBaseUrl + 'deliveryManagement/management/id/' + deliveryId;
     if (fInclude) {
       queryUrl = queryUrl + '?fInclude=' + fInclude;
     }
     return this.http.get(queryUrl);
   }

   assignOrderToAgent(moduleName, payload) {
     return this.http.post(this.configService.appConfig.appBaseUrl + 'deliveryManagement/management/?module=' + moduleName, payload);
   }

   cancelAssignment(assignmentId) {
     return this.http.delete(this.configService.appConfig.appBaseUrl + 'deliveryManagement/management/cancelAssignment/' + assignmentId);
   }

   getCustomerLink(id) {
     return this.http.get(this.configService.appConfig.appBaseUrl + 'deliveryManagement/management/customerLink/' + id);
   }

   completeRoundTrip(deliveryGrpId) {
     return this.http.put(this.configService.appConfig.appBaseUrl + 'deliveryManagement/assignment/group/completeRoundTrip/' + deliveryGrpId, null);
   }

   sendCustomerTrackLink(id) {
     return this.http.get(this.configService.appConfig.appBaseUrl + 'deliveryManagement/management/sendCustomerTrackLink/' + id);
   }

   getAllActiveAssignmentsByAgentId(agentId) {
     return this.http.get(this.configService.appConfig.appBaseUrl + 'deliveryManagement/management/ordersAssigned/agentId/' + agentId);
   }

   editAssignmentOrders(moduleName, payload: EditAssignmentPayload) {
     return this.http.post(this.configService.appConfig.appBaseUrl + 'deliveryManagement/management/editAssignment/?module=' + moduleName, payload)
   }

   canEditAssigmentForAgent(agentId) {
     return this.http.get(this.configService.appConfig.appBaseUrl + 'deliveryManagement/management/canEditAssignment/agentId/' + agentId)
   }

   canEditAssignmentForAgentByModuleName(moduleName){
     const url = this.configService.appConfig.appBaseUrl + 'deliveryManagement/management/editableAgents/moduleType/' + moduleName;
    return this.http.get(url);
   }

   getEtaForInProgressOrder(id) {
     return this.http.get(this.configService.appConfig.appBaseUrl + 'deliveryManagement/management/eta/' + id);
   }

   getEtaForInProgressOrders(ids) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'deliveryManagement/management/eta/deliveryIds/' + ids);
  }

   getAllActiveOrdersForAgent(agentId, isMinifiedData?) {
     let queryUrl =  (this.configService.appConfig.appBaseUrl + 'deliveryManagement/management/activeOrders/agentId/' + agentId);
     if (isMinifiedData) {
       queryUrl = queryUrl + '?min=' + isMinifiedData;
     }
     return this.http.get(queryUrl);
   }

   getAllTimeSlots() {
    const queryUrl = `${this.configService.appConfig.appBaseUrl}orderToDelivery/order/settings/order?reqFields=assignmentWindow`;
     return this.http.get<IAssignmentWindowForO2DWithWindow>(queryUrl);
   }
   getAllStatusOptions(moduleName?){
    let queryUrl =  (this.configService.appConfig.appBaseUrl + 'deliveryManagement/management/delivery/status?type=delivery');
    if(moduleName){
        queryUrl = queryUrl + "&moduleType="+ moduleName
    }
    return this.http.get(queryUrl)
   }

   getGroupedAgentAssignedOrdersByOrderId(orderId) {
     const queryUrl = this.configService.appConfig.appBaseUrl + 'deliveryManagement/assignment/group/allOrders?orderId=' + orderId;
     return this.http.get(queryUrl);
   }

   sendOrderStatusData(data: any) {
     const bodyObj = {'deliveryId': `${data.id}`, 'status': `${data.status}`, 'date': null, 'reason': null};
     const queryUrl = (this.configService.appConfig.appBaseUrl + 'deliveryManagement/management/delivery/status');
     return this.http.put(queryUrl, bodyObj);
   }

   buildFullTimelineContentArray(rowData) {
     // this._timelineTab.timelineContentArray = [];
     const _this = this;
     const timelineContentArrayCopy: any = [];
     const modifyTimeLine = [];
     if (rowData.timeLine && rowData.timeLine.length > 0) {
       forEach(rowData.timeLine, function (value) {
         // console.log(value);
         const deliveryStatus =
           value.deliveryStatusObj && value.deliveryStatusObj.date
             ? value.deliveryStatusObj
             : value.deliveryStatus && value.deliveryStatus.date
               ? value.deliveryStatus
               : null;
         const eventStatus =
           value.event && value.event.date
             ? value.event
             : value.event && value.event.date
             ? value.event
             : null;
         let timeLineData = {};
         if (deliveryStatus || eventStatus) {
           if (deliveryStatus) {
             timeLineData = merge(timeLineData, deliveryStatus);
             timeLineData["status"] = deliveryStatus.deliveryStatus;
           } else if (eventStatus) {
             timeLineData = merge(timeLineData, eventStatus);
             timeLineData["deliveryStatus"] = eventStatus.status;
           }
           if (value.agent && value.agent.name) {
             timeLineData["agentName"] = value.agent.name;
             if (value.agent.mobileNumber) {
               timeLineData["agentName"] = timeLineData["agentName"] + ' (' + 'Mobile : ' + value.agent.mobileNumber + ')'
             } else if(value.agent.mobileNo) {
               timeLineData["agentName"] = timeLineData["agentName"] + ' (' + 'Mobile : ' + value.agent.mobileNo + ')'
             }
           }
           if (value.agent) {
             value.agent.otp ? timeLineData["deliveryOtp"] = (value.agent.otp).toString() : value.agent.deliveryOtp ? timeLineData["deliveryOtp"] = (value.agent.deliveryOtp).toString() : ''
             /* if (value.agent.otp) {
               timeLineData["deliveryOtp"] = (value.agent.otp).toString();
             } else if (value.agent.deliveryOtp) {
                 timeLineData["deliveryOtp"] = (value.agent.deliveryOtp).toString();
             } */
           }
           if (value.logisticProvider && value.logisticProvider.name) {
             timeLineData["logisticProvider"] = value.logisticProvider.name;
           }
           if(value.deliveryStatusObj && value.deliveryStatusObj['location']){
             timeLineData['location'] = value.deliveryStatusObj['location'];
           }

           modifyTimeLine.push(timeLineData);
         }
         // console.log(timeLineData);
       });
       let timeLine = [];
       timeLine = orderBy(modifyTimeLine, ["date"], ["desc"]);
       forEach(timeLine, function (value) {
         const header = value.status;
         const filterPipe = new NamingConventionFilterPipe();
         const fiteredString = filterPipe.transform(header ? header : '');
         let content = '';
         const contents: any = [];
         let description = '';
         let reason = '';
         let location: any = null;
         if (value['agentName']) {
           let label = 'Agent : ';
           if (rowData.moduleType && rowData.moduleType === MMD_MODULE_NAME) {
             label = 'Carrier : ';
           }
           content = label + value['agentName'];
           contents.push(content);
         }
         if (value['logisticProvider']) {
           content = "Lp : " + value["logisticProvider"];
           contents.push(content);
         }
         if (value["boxTemp"] && value["boxTemp"] !== "NA") {
            let label = "Box Temp : ";
            if(rowData.moduleType && rowData.moduleType ===  MMD_MODULE_NAME) {
              label = "Temp : ";
            }
           content = label + parseFloat(value["boxTemp"].toFixed(2)) + " °C";
           contents.push(content);
         }
         /* if (value["reason"]) {
           content = "Reason : " + value["reason"];
           contents.push(content);
         } */
         if (value["deliveryOtp"]) {
           content = "Delivery OTP : " + value["deliveryOtp"];
           contents.push(content);
         }
         if (value["description"]) {
           description = value["description"];
         }
         if (value["reason"]) {
           reason =  value["reason"];
         }
         if(value.location){
           location = value.location;
         }

         timelineContentArrayCopy.push(
           _this.buildTimelineObject(
             value.imageId,
             "",
             fiteredString,
             contents,
             moment(value.date).format("MMMM Do YYYY, hh:mm:ss a"),
             null,
             description,
             reason,
             location
           )
         );
       });
     }

     return timelineContentArrayCopy;
   }

   buildTimelineObject(
     imageId,
     iconToUse,
     header,
     contents,
     timestamp?,
     iconType?,
     description?,
     reason?,
     location?
   ) {
     const obj = {
       icon: '',
       header: '',
       contents: [],
       iconType: '',
       image: '',
       timestamp: '',
       eventDescription: '',
       eventReason: '',
       location: {}
     };
     obj.icon = iconToUse;
     obj.header = header;
     obj.contents = contents;
     obj.image = imageId;
     obj.timestamp = timestamp ? timestamp : null;
     obj.eventDescription = description ? description : null;
     obj.eventReason = reason ? reason : null;
     obj.location = location ? location : null;
     if(iconType){

     }
     return obj;
   }

   modifyTheData(data: any){
     // let data = cloneDeep(dataObj)
     data['custDetails'] = [];
     if (data && data.orderId) {
       data['nestedOrderId'] = data.orderId;
     }
     if (data && data.customerDetails) {
       if (
         data.customerDetails.firstName &&
         data.customerDetails.lastName
       ) {
         data["firstName"] =
           data.customerDetails.firstName +
           " " +
           data.customerDetails.lastName;
         data['custDetails'].push(data["firstName"]);
       } else if (data.customerDetails.firstName) {
         data["firstName"] = data.customerDetails.firstName;
         data["firstName"] = data.customerDetails.firstName;
         data['custDetails'].push(data["firstName"]);

       }

       if (data.customerDetails.location) {
         let cityWithPincode = '';
         if (data.customerDetails.location.city) {
           data["city"] = data.customerDetails.location.city;
           data["city"] = data.customerDetails.location.city;
           cityWithPincode = data.customerDetails.location.city;
         }
         if (data.customerDetails.location.pincode) {
           data["pincode"] = data.customerDetails.location.pincode;
           data["pincode"] =
             data.customerDetails.location.pincode;
           cityWithPincode = cityWithPincode + ' - ' + data.customerDetails.location.pincode;
         }
         data['custDetails'].push(cityWithPincode);
       }

       if (data.customerDetails.mobileNumber) {
         data["mobileNumber"] = data.customerDetails.mobileNumber;
         data["customerMobile"] =
           data.customerDetails.mobileNumber;
         let lang = this.langUtilService.getTranslation('sys.shipping_number')
        const shippingPh = `${lang}:${data['mobileNumber']}`
           data['custDetails'].push(shippingPh);
       }
       if (data.billAddress && data.billAddress.phone) {
        let lang = this.langUtilService.getTranslation('sys.billing_number')
        const billingPh = `${lang}:${data.billAddress.phone}`
         data['custDetails'].push(billingPh);
       }
     }
     // ----------------


     data['items'] = []
     if (
       data &&
       data.orderDetails.items &&
       data.orderDetails.items.length > 0
     ) {
       let item: string;
       item = "";
       const itemList = [];
       _.forEach(data.orderDetails.items, function(value) {
           item = value["name"] + " - Qty: " + value["quantity"];
           itemList.push(item)
        });
       data["items"] = itemList;
     }
     if( data.pickUpCity.city){
       data['pickupCity'] = data.pickUpCity.city;
     }
     return data
   }
   getAssetData(assetId){
    if(!assetId){
      return of({})
    }
     const queryUrl = (this.configService.appConfig.appBaseUrl + 'asset/' + assetId);
     return this.http.get(queryUrl);
   }

   getAssetsDataByIds(assetIds: string, fI?, isLinkedReq?){
     let queryUrl = (this.configService.appConfig.appBaseUrl + 'asset?ids='+assetIds)
     if (fI) {
       queryUrl = queryUrl + '&fI=' + fI;
     }
     if (isLinkedReq) {
       queryUrl = queryUrl + '&isLinkedReq=' + isLinkedReq;
     }
     return this.http.get(queryUrl);
   }

   getAllDeliveryAssignments(obj){
     // status should be 'completed' or 'pending'
     let queryUrl = `${this.configService.appConfig.appBaseUrl}deliveryManagement/assignment/group/all?status=${obj?.status}&module=${obj?.moduleName}`

     if (obj?.isCount) {
       queryUrl = `${queryUrl}&isCount=${obj?.isCount}`;
     }
     if (obj?.skip || obj?.skip == 0) {
       queryUrl = `${queryUrl}&skip=${obj?.skip}`;
     }
     if (obj?.limit) {
       queryUrl = `${queryUrl}&limit=${obj?.limit}`;
     }
     if (obj?.startDate) {
       queryUrl = `${queryUrl}&startDate=${obj?.startDate.format('x')}`;
     }
     if (obj?.endDate) {
       queryUrl = `${queryUrl}&endDate=${obj?.endDate.format('x')}`;
     }
     if (obj?.fInclude) {
       queryUrl = `${queryUrl}&fI=${obj?.fInclude}`;
     }
     if (obj?.fExclude) {
       queryUrl = `${queryUrl}&fE=${obj?.fExclude}`;
     }
     if (obj?.carrierIds) {
       queryUrl = `${queryUrl}&carrierIds=${obj?.carrierIds}`;
     }
     if (obj?.orderPickUpCityId) {
       queryUrl = `${queryUrl}&orderPickUpCityId=${obj?.orderPickUpCityId}`;
     }
     if (obj?.orderPickUpStoreId) {
       queryUrl = `${queryUrl}&orderPickUpStoreId=${obj?.orderPickUpStoreId}`;
     }
     if(obj?.isLinked){
      queryUrl = `${queryUrl}&isLinked=${obj?.isLinked}`;
     }
     if(obj?.dateField){
      queryUrl = `${queryUrl}&dateField=${obj?.dateField}`;
     }
     if(obj?.search){
      queryUrl = `${queryUrl}&search=${obj?.search}`;
     }
     if(obj?.orderRoutePlanIds){
      queryUrl = `${queryUrl}&orderRoutePlanIds=${obj?.orderRoutePlanIds}`;
     }
     if(obj?.pagination){
      queryUrl = `${queryUrl}&pagination=${obj?.pagination}`;
     }
     return this.http.get(queryUrl)
   }

   getDistanceTravelledByGroupId(groupId) {
     const queryUrl = (this.configService.appConfig.appBaseUrl + 'deliveryManagement/assignment/group/distanceTravelled/' + groupId);
    return this.http.get(queryUrl);
   }

   checkCanEditOrderStatus(deliveryId){
     const url = (this.configService.appConfig.appBaseUrl + 'deliveryManagement/management/canEditAssignment/deliveryId/' + deliveryId);
    return this.http.get(url)
   }

   checkCanEditOrderByModuleName(moduleType){
     const url = (this.configService.appConfig.appBaseUrl + 'deliveryManagement/management/editableDeliveries/moduleType/' + moduleType);
    return this.http.get(url);
   }


   getAllAssignedCarriers(params){
    let queryUrl = `${this.configService.appConfig.appBaseUrl}deliveryManagement/assignment/group/allCarrier?moduleType=${params?.moduleName}&status=${params?.status}`
    if (params?.isCount) {
      queryUrl = `${queryUrl}&isCount=${params?.isCount}`;
    }
    if (params?.skip || params?.skip == 0) {
      queryUrl = `${queryUrl}&skip=${params?.skip}`;
    }
    if (params?.limit) {
      queryUrl = `${queryUrl}&limit=${params?.limit}`;
    }
    if (params?.startDate) {
      queryUrl = `${queryUrl}&startDate=${params?.startDate.format('x')}`;
    }
    if (params?.endDate) {
      queryUrl = `${queryUrl}&endDate=${params?.endDate.format('x')}`;
    }
    if (params?.fInclude) {
      queryUrl = `${queryUrl}&fI=${params?.fInclude}`;
    }
    if(params?.orderPickUpStoreId) {
      queryUrl = `${queryUrl}&orderPickUpStoreId=${params?.orderPickUpStoreId}`;
    }
    if(params?.orderPickUpCityId) {
      queryUrl = `${queryUrl}&orderPickUpCityId=${params?.orderPickUpCityId}`;
    }
     return this.http.get(queryUrl);
   }

   forceStopRoundTrip(obj) {
     const deliveryId = obj.id;
     const queryUrl = (this.configService.appConfig.appBaseUrl + 'deliveryManagement/assignment/group/forceDelivered/' + deliveryId);
     const body = {
       'reason': obj.reason
     };
     return this.http.put(queryUrl, body);
   }

 }

 export interface EditAssignmentPayload {
    agentId?: string
    orderIds?: string[]
    deliverySequence?: DeliverySequence
    isRoundTrip?: boolean;
    removeOrderIds?: string[];
    routePlanIds?: string[];
    noCarrier?: boolean;
  }

export interface DeliverySequence {
    [key: string]: string;
  }

